

















import type { PropType } from 'vue';
import themeConfig from '@themeConfig';
import type { SuperAdminOperatorMenuItem } from '@/api/schema';

export default {
  name: 'SuperAdminOperatorMenuLink',
  props: {
    item: {
      type: Object as PropType<SuperAdminOperatorMenuItem>,
      default: (): SuperAdminOperatorMenuItem => {
        return {} as SuperAdminOperatorMenuItem;
      },
      required: true
    },
    selectedComponent: {
      type: String as PropType<string | null>,
      default: (): string => ''
    },
    isLastItem: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed: {
    alternateIcon(): string {
      return themeConfig.menu.groupChildIcon;
    }
  }
};
